<template>
  <div>
    <span v-if="items.length === 0">{{ noDataText }}</span>
    <v-data-table
      v-show="items.length > 0"
      :headers="headers"
      :items="items"
      dense
      class="v-data-table-with-borders ma-3 elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import axios from "@axios";
import { noDataText } from "@/utils/constants";
import debounce from "lodash/debounce";

export default {
  name: "DiscoverRealTimeWordsFrequencyTable",
  props: {
    language: {
      type: String,
      default: "fr",
      validator(value) {
        return ["fr", "en", ""].includes(value);
      },
    },
  },
  setup() {},
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      noDataText,
      headers: [
        { text: "Mot", value: "word", sortable: false },
        { text: "Fréquence", value: "frequency", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = { language: this.language };
      const response = await axios.get(
        "google-discover-real-time/words-frequency",
        {
          params: params,
        }
      );
      console.log(response);
      this.items = response.data.items;
    },
  },
  computed: {},
  watch: {
    language: {
      //immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.debouncedGetData();
        }
      },
    },
  },
};
</script>
<style scoped>
.v-data-table-with-borders .v-data-table__wrapper td,
.v-data-table-with-borders .v-data-table__wrapper th {
  border-right: 1px solid #e0e0e0;
}

.v-data-table-with-borders .v-data-table__wrapper th:first-child,
.v-data-table-with-borders .v-data-table__wrapper td:first-child {
  border-left: 1px solid #e0e0e0;
}

.v-data-table-with-borders .v-data-table__wrapper tr {
  border-bottom: 1px solid #e0e0e0;
}
.parent-header {
  background-color: #f5f5f5;
  font-weight: bold;
}
.child-header.sortable {
  cursor: pointer;
}

.child-header.sortable:hover {
  text-decoration: underline;
}
</style>
